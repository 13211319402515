import { ApiResponse } from '../models/ApiResponse';
import { PeriodicReviewChecklistItemInstance } from '../models/Form';
import { PeriodicReviewCreationResponse } from '../models/PeriodicReviewCreationResponse';
import BaseService from './BaseService';

export default class PeriodicReviewService extends BaseService {
  public static modifyChecklistItem(
    clientFormId: string,
    checklistItemId: string,
    checked: boolean,
  ): Promise<ApiResponse<PeriodicReviewChecklistItemInstance[]>> {
    return this.post(`/v1/periodic-reviews/${clientFormId}/checklist`, { checklistItemId, checked });
  }

  public static start(
    activityId: string,
    dueDateUtc: Date | null,
    effectiveDateUtc: Date | null,
  ): Promise<ApiResponse<PeriodicReviewCreationResponse>> {
    return this.post(`v1/periodic-reviews/${activityId}/start`, { dueDateUtc, effectiveDateUtc });
  }
}
