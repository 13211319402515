import { FC, useCallback } from 'react';
import { PeriodicReviewChecklistItemInstance } from '../../models/Form';
import Checkbox from '../shared/form-control/Checkbox';
import LanguageUtils from '../../utils/LanguageUtils';
import PeriodicReviewService from '../../services/PeriodicReviewService';

type Props = {
  clientFormId: string;
  checklist: PeriodicReviewChecklistItemInstance[];
  disabled?: boolean;
  onChecklistChanged: (checklist: PeriodicReviewChecklistItemInstance[]) => void;
};

const PeriodicChecklistViewer: FC<Props> = (props) => {
  const { checklist, clientFormId, disabled, onChecklistChanged } = props;

  const onTick = useCallback(
    (id: string, value: boolean) => {
      PeriodicReviewService.modifyChecklistItem(clientFormId, id, value).then((res) => {
        onChecklistChanged(res.data);
      });
    },
    [clientFormId, onChecklistChanged],
  );

  if (!checklist) return null;

  return (
    <div className="flex flex-wrap gap-2 py-2">
      {checklist.map((x) => (
        <div key={x.id} className="min-w-full max-w-0 flex-grow truncate">
          <Checkbox
            disabled={disabled}
            value={x.checked}
            onChange={(v) => onTick(x.id, v)}
            label={LanguageUtils.getTranslation('text', x.translations ?? {})}
          />
        </div>
      ))}
    </div>
  );
};

export default PeriodicChecklistViewer;
