import { FC, useMemo } from 'react';
import { VersionState } from '../../../models/ClientFormVersionHistory';
import { useTranslation } from 'react-i18next';

const VersionStateLabel: FC<{ state: VersionState }> = ({ state }) => {
  const { t } = useTranslation(['documents', 'common']);
  const labelColor = useMemo(() => {
    switch (state) {
      case VersionState.Draft:
      default:
        return 'text-gray-2';
      case VersionState.Superseded:
        return 'text-status-tag-3';
      case VersionState.Master:
        return 'text-status-tag-4';
    }
  }, [state]);

  const lableText = useMemo(() => {
    switch (state) {
      case VersionState.Draft:
      default:
        return t('common:document-status.in-progress');
      case VersionState.Superseded:
        return t('index-modal.labels.superseded');
      case VersionState.Master:
        return t('index-modal.labels.master');
    }
  }, [state, t]);
  return <span className={`${labelColor} text-dpm-14 ml-2 font-medium`}>{lableText}</span>;
};

export default VersionStateLabel;
