import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClientForm } from '../../models/ClientForm';
import { ClientFormUser } from '../../models/ClientFormUser';
import { FormListItem, PeriodicReviewChecklistItemInstance } from '../../models/Form';
import { FormTypeKeys } from '../../models/FormTypes';
import ClientFormService from '../../services/ClientFormService';
import Loader from '../shared/Loader';
import { ModalContext } from '../../contexts/ModalContext';
import StandardModal from '../shared/modal/variants/StandardModal';
import FormInfoTabs from './FormInfoTabs';
import { useFetchAssociations } from '../../hooks/useFetchAssocations';
import { Associations } from '../../models/Association';

type FormInfoModalProps = {
  form: FormListItem | ClientForm | { id: string };
  clientFormUsers: ClientFormUser[];
  associations?: Associations[];
  open: boolean;
  onClose: () => void;
  onChecklistChanged?: (checklist: PeriodicReviewChecklistItemInstance[]) => void;
};

const FormInfoModal: FC<FormInfoModalProps> = (props) => {
  const { form, open, onClose, clientFormUsers, onChecklistChanged, associations } = props;
  const [clientForm, setClientForm] = useState<ClientForm | null>('form' in form ? form : null);
  const { t } = useTranslation(['form', 'common']);

  useEffect(() => {
    if (!open || clientForm) return;

    ClientFormService.getForm(form.id).then((res) => setClientForm(res.data));
  }, [clientForm, form.id, open]);

  const onChecklistChangedInternal = useCallback(
    (checklist: PeriodicReviewChecklistItemInstance[]) => {
      setClientForm((prev) => prev && { ...prev, periodicReviewChecklist: checklist });
      onChecklistChanged && onChecklistChanged(checklist);
    },
    [onChecklistChanged],
  );

  const { associations: fetchedAssociations } = useFetchAssociations(clientForm?.id, !associations);

  return (
    <ModalContext.Provider value={{ open: open, onClose: onClose, modalWidth: 'w-3/5 min-h-128' }}>
      <StandardModal title={clientForm ? t('form:info-modal.heading', { type: t(FormTypeKeys[clientForm.form.type]) }) : ''}>
        {clientForm ? (
          <FormInfoTabs
            clientForm={clientForm}
            clientFormUsers={clientFormUsers}
            onChecklistChanged={onChecklistChangedInternal}
            associations={associations || fetchedAssociations}
          />
        ) : (
          <Loader size={8} />
        )}
      </StandardModal>
    </ModalContext.Provider>
  );
};

export default FormInfoModal;
