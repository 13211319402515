import { ComponentRef, useCallback, useMemo, useRef } from 'react';
import { SelectItemContextProvider } from '../contexts/select-items/SelectItemsContext';
import { DataJobSourceType } from '../models/DataImport';

export const useDownloadPdf = (forceMode?: 'pdf' | 'excel') => {
  const selectionContextRef = useRef<ComponentRef<typeof SelectItemContextProvider>>(null);

  const triggerModal = useCallback(
    (clientFormId: string, name: string) => {
      selectionContextRef.current?.setSource({ type: DataJobSourceType.Activity });
      selectionContextRef.current?.selectAll(false);
      selectionContextRef.current?.selectIndividual(clientFormId);
      selectionContextRef.current?.setShowDataExportModal(true, forceMode, name || '');
    },
    [forceMode],
  );

  const modal = useMemo(() => {
    return <SelectItemContextProvider ref={selectionContextRef} />;
  }, []);

  return { triggerPdfDownload: triggerModal, pdfDownloadModal: modal };
};
