import { useMemo, useEffect, useCallback, useState } from 'react';
import { FormReferenceDirection } from '../models/ClientForm';
import { ClientTemplateFormUserResponse } from '../models/ClientTemplateFormUser';
import { FormType } from '../models/FormTypes';
import ClientFormService from '../services/ClientFormService';
import ClientTemplateFormService from '../services/ClientTemplateFormService';
import LanguageUtils from '../utils/LanguageUtils';
import { getUserResourcePermissions } from './permissions/useResourcePermissions';
import { useRecoilValue } from 'recoil';
import { currentClientAtom } from '../recoil/atoms/Clients';
import { currentUserAtom } from '../recoil/atoms/Auth';
import { useTranslation } from 'react-i18next';
import { EventSystem } from '../events/EventSystem';
import { Associations } from '../models/Association';
import { ClientFormUserResponse } from '../models/ClientFormUser';
import { hasPermission } from './permissions/usePermissions';
import { Roles } from '../models/Role';
import { Access } from '../models/Access';

export const useFetchAssociations = (clientFormId: string | undefined, autoFetch: boolean, direction?: FormReferenceDirection) => {
  const currentClient = useRecoilValue(currentClientAtom);
  const currentUser = useRecoilValue(currentUserAtom);
  const {
    i18n: { language: currentLanguage },
  } = useTranslation();

  const [associations, setAssociations] = useState<Associations[]>([]);

  const clientFormTemplateService = useMemo(() => currentClient && new ClientTemplateFormService(currentClient.id), [currentClient]);

  const getTargetReferences = useCallback(() => {
    if (clientFormId && (direction === undefined || direction === FormReferenceDirection.Target)) {
      return ClientFormService.getReferences(clientFormId, FormReferenceDirection.Target);
    }

    return Promise.resolve({ data: [] });
  }, [clientFormId, direction]);

  const getSourceReferences = useCallback(() => {
    if (clientFormId && (direction === undefined || direction === FormReferenceDirection.Source)) {
      return ClientFormService.getReferences(clientFormId, FormReferenceDirection.Source);
    }

    return Promise.resolve({ data: [] });
  }, [clientFormId, direction]);

  const fetchAssociations = useCallback(() => {
    if (!clientFormId || !currentClient || !clientFormTemplateService) return;

    Promise.all([getTargetReferences(), getSourceReferences()]).then(async ([targetResult, sourceResult]) => {
      const targetIds = new Set(targetResult.data.map((x) => x.id));
      const references = [...targetResult.data, ...sourceResult.data].filter(
        (value, index, self) => self.findIndex((y) => y.id === value.id) === index,
      );
      const resourceTemplateIds = [...new Set(references.filter((x) => x.type === FormType.Resource).map((x) => x.templateId))];
      const activityIds = [...new Set(references.filter((x) => x.type !== FormType.Resource).map((x) => x.id))];

      const [resourceTemplateUsers, activityUsers] = await Promise.all([
        await Promise.all(
          resourceTemplateIds.map(
            (id) =>
              new Promise<{ id: string; users: ClientTemplateFormUserResponse[] }>((resolve) =>
                clientFormTemplateService
                  .getUsers(id)
                  .then((res) => resolve({ id, users: res.data }))
                  .catch(() => resolve({ id, users: [] })),
              ),
          ),
        ),
        await Promise.all(
          activityIds.map(
            (id) =>
              new Promise<{ id: string; users: ClientFormUserResponse[] }>((resolve) =>
                ClientFormService.getFormUsers(id)
                  .then((res) => resolve({ id, users: res.data }))
                  .catch(() => resolve({ id, users: [] })),
              ),
          ),
        ),
      ]);

      const result: Record<string, Associations> = {};
      for (const form of references) {
        let canView = true;
        if (!result[form.templateId]) {
          result[form.templateId] = {
            templateId: form.templateId,
            templateTitle: form.isSystemTemplateForm
              ? LanguageUtils.getTranslation('name', form.templateModuleTranslations)
              : LanguageUtils.getTranslation('title', form.translations, currentLanguage),
            associations: [],
          };
        }

        if (form.type === FormType.Resource && currentUser) {
          const users = resourceTemplateUsers.find((x) => x.id === form.templateId)?.users || [];
          const { canViewRecords } = getUserResourcePermissions(
            users.map((el: ClientTemplateFormUserResponse) => {
              return { ...el.user, role: el.role };
            }),
            currentUser,
            currentClient.id,
          );

          canView &&= canViewRecords;
        } else {
          const isManagement = hasPermission(currentClient.id, currentUser, Roles.Management);
          const isAssigned = !!activityUsers.find((x) => x.id === form.id)?.users.find((x) => x.userId === currentUser?.id);
          canView &&= form.accessType === Access.restricted ? isAssigned : isManagement || isAssigned;
        }
        const dependency = { ...form, canView: canView, isTarget: targetIds.has(form.id) };
        result[form.templateId].associations.push(dependency);
      }

      setAssociations(Object.values(result));
    });
  }, [clientFormId, clientFormTemplateService, currentClient, currentLanguage, currentUser, getSourceReferences, getTargetReferences]);

  useEffect(() => {
    if (autoFetch) {
      fetchAssociations();
    }
  }, [autoFetch, fetchAssociations]);

  useEffect(() => {
    const handler = () => {
      fetchAssociations();
    };

    EventSystem.listen('associations-updated', handler);
    return () => EventSystem.stopListening('associations-updated', handler);
  }, [fetchAssociations]);

  return { fetchAssociations, associations };
};
